.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    /* width: calc(300px + 1rem); */
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    /* background-color: #008f68;
    color: #fae042; */
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #e0d9d980;
}

.react-calendar {
    width: 100% !important;
    /* height: 300px !important; */
}

.react-calendar button {
    padding: 30px 0px 30px 0px;
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar__navigation {
    height: 100% !important;
    border-bottom: 1px solid #a0a096;
}

.react-calendar__month-view__days__day--weekend {
    color: #000000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}

.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 0 !important;
}

.react-time-picker__clock{
    display: none !important;
}

.react-time-picker__clock-button {
    display: none !important;
}

.react-time-picker{
    width: 150px !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.evidence-field {
    width: 20%;
}

.left-report-content {
    padding-left: 0 !important;
    padding-right: 25px !important;
}

.center-report-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.right-report-content {
    padding-right: 0 !important;
    padding-left: 25px !important;
}

@media screen and (max-width: 767px) {
    .evidence-field {
        width: 33%;
    }
    .left-report-content {
        padding: 20px 0 !important;
    }
    .center-report-content {
        padding: 20px 0 !important;
    }
    .right-report-content {
        padding: 20px 0 !important;
    }
}

@media screen and (max-width: 1250px) {
    .evidence-field {
        width: 33%;
    }
}