.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,.5);
    font-weight: 600;
}

.navbar {
    position: fixed !important;
    min-height: 48px;
    border: 1px solid transparent;
    background-color: #223042;
    border-color: #1d2938;
    width: 100%;
    z-index: 400;
}

@media screen and (min-width: 767px) {
    .nav {
        padding-left: 60px;
    }

}

@media screen and (max-width: 767px) {
    .nav {
        padding-top: 25px;
    }
} 

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link:focus {
    color: rgb(218, 218, 218);
    font-weight: 600;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #fff;
    font-weight: 600;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("../assets/menu.png");
    height: 30px;
    width: 30px;
}