.image-background {
    background: url(../assets/background.jpg) no-repeat center fixed;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
}

.divcontainer {
    /* height: 250px; */
    padding: 30px 0px 30px 0px;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 3px;
}

#login {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    /* background-color: #333; */
    background-color: rgba(51, 51, 51, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 45%;
    bottom: 60px;
    font-size: 17px;
}

#login.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.left {
    position: relative;
}

.right {
    position: relative;
}

@media screen and (max-width:767px) {
    .divcontainer {
        justify-content: space-between;
    }
    .left {
        order: 2;
    }
    .right {
        order: 1;
    }
    .bottom {
        order: 3;
        padding-top: 10px;
    }
}

.input-error {
    color: red;
    display: none;
}
.error {
    border: 2px solid #ff00008f !important;
    box-shadow: none!important;
}